import styled                from 'styled-components';
import { Typography }        from '@material-ui/core';
import DialogContent         from '@material-ui/core/DialogContent';
import DialogContentText     from '@material-ui/core/DialogContentText';
import { TextBrand }         from '../styledComponents';
import { UploadDialogTitle } from './modals/styles';

const TermsText = styled(DialogContentText)`
  font-family: 'Lato';
  font-size: 16px;
  color: #272932;
  line-height: 26px;

  ul {
    list-style: disc;
    margin-left: 30px;
  }
`;

const Content = styled(DialogContent)`
  word-break: break-word;
  
  ${ TextBrand } {
    margin-bottom: 5px;
  }
`;

export const PrivacyContent = () => (
  <>
    <UploadDialogTitle>
      <p>Privacy Policy</p>
      <TermsText>Last Updated: 2025</TermsText>
    </UploadDialogTitle>

    <Content>
      <Typography variant="h3">The Family Core, LLC Privacy Policy</Typography>

      <TermsText>
        This privacy policy has been compiled to better serve those who are concerned with how ‘Personally
        Identifiable Information’ (PII) is being used online. PII, as described in US privacy law and
        information security, is information that can be used on its own or with other information to identify,
        contact, or locate a single person, or to identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally
        Identifiable Information in accordance with our website.
      </TermsText>

      <TextBrand>
        What personal information do we collect from the people that visit our blog, website or
        app?
      </TextBrand>

      <TermsText>
        When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, or other details to help you with your experience.
      </TermsText>

      <TextBrand>When do we collect information?</TextBrand>

      <TermsText>
        We collect information from you when you or someone else enter information on our site.
      </TermsText>

      <TextBrand>How do we use your information?</TextBrand>

      <TermsText>
        We may use the information we collect from you when you register, make a purchase, sign up for our newsletter,
        respond to a survey or marketing communication, surf the website, or use certain other site features in the
        following ways:
        <ul>
          <li>
            To personalize your experience and to allow us to deliver the type of content and product offerings in
            which you are most interested.
          </li>
          <li>To administer a contest, promotion, survey or other site feature.</li>
          <li>To ask for ratings and reviews of services or products</li>
        </ul>
      </TermsText>

      <TextBrand>How do we protect your information?</TextBrand>

      <TermsText>
        We do not use vulnerability scanning and/or scanning to PCI standards. We only provide articles and information.
        We never ask for credit card numbers. We do not use Malware Scanning. Your personal information is contained behind
        secured networks and is only accessible by a limited number of persons who have special access rights to such systems
        and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted
        via Secure Socket Layer (SSL) technology.
      </TermsText>

      <TermsText>
        We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information. All transactions are processed through a gateway provider and are not stored or processed on our servers.
      </TermsText>

      <TextBrand>Do we use &lsquo;cookies&lsquo;?</TextBrand>

      <TermsText>
        Yes. Cookies are small files that a site or its service provider transfers to your computer‘s
        hard drive through your Web browser (if you allow) that enables the site‘s or service provider‘s
        systems to recognize your browser and capture and remember certain information. For instance, we
        use cookies to help us remember and process the items in your shopping cart. They are also used to
        help us understand your preferences based on previous or current site activity, which enables us to
        provide you with improved services. We also use cookies to help us compile aggregate data about site
        traffic and site interaction so that we can offer better site experiences and tools in the future.
      </TermsText>

      <TextBrand>We use cookies to:</TextBrand>

      <TermsText>
        <ul>
          <li>Understand and save user&lsquo;s preferences for future visits.</li>
          <li>Compile aggregate data about site traffic and site interactions in order to offer better site
              experiences and tools in the future. We may also use trusted third-party services that track this
              information on our behalf.
          </li>
        </ul>
        You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off
        all cookies. You do this through your browser settings. Since every browser is a little different, look at
        your browser&lsquo;s Help Menu to learn the correct way to modify your cookies.
      </TermsText>

      <TextBrand>If users disable cookies in their browser:</TextBrand>

      <TermsText>
        If you turn cookies off, some of the features that make your site experience more efficient may not function
        properly.
      </TermsText>

      <TextBrand>Third-party disclosure</TextBrand>

      <TermsText>
        Do we disclose the information we collect to Third-Parties?
        We reserve the right to sell, trade, or otherwise transfer collected email information to relevant outside
        parties, as long as our users have opted in, indicating their interest in receiving promotions.
      </TermsText>

      <TextBrand>We engage in this practice:</TextBrand>

      <TermsText>
        To enable companies to follow up with interested participants’ Personally Identifiable Information.
      </TermsText>

      <TextBrand>Third-party links</TextBrand>

      <TermsText>
        Occasionally, at our discretion, we may include or offer third-party products or services on our website.
        These third-party sites have separate and independent privacy policies. We therefore have no responsibility or
        liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
        of our site and welcome any feedback about these sites.
      </TermsText>

      <TextBrand>Google</TextBrand>
      <TextBrand>Google Calendar and OAuth</TextBrand>
      <TextBrand>Introduction</TextBrand>

      <TermsText>
        The following describes how The Family Core ("we", "our", or "us") accesses, uses, stores, and shares information
        when you use our services that integrate with Google OAuth.
      </TermsText>

      <TextBrand>Information We Access</TextBrand>

      <TermsText>
        When you use Google OAuth to access our services, we may collect:
        <ul>
          <li>Your name</li>
          <li>Email address</li>
          <li>Profile picture</li>
          <li>Any other information you choose to make available through your Google account</li>
        </ul>
      </TermsText>

      <TextBrand>How We Use Your Information</TextBrand>

      <TermsText>
        We use the information collected through Google OAuth to:
        <ul>
          <li>Authenticate your identity</li>
          <li>Provide and improve our services</li>
          <li>Communicate with you about our services</li>
        </ul>
      </TermsText>

      <TextBrand>How We Store Your Information</TextBrand>

      <TermsText>
        We will retain your personal information for the length of time needed to
        fulfill the purposes outlined in this privacy policy unless a longer retention
        period is required or permitted by law.
      </TermsText>

      <TextBrand>Data Sharing and Disclosure</TextBrand>

      <TermsText>
        We do not sell your personal information. We may share your information:
        <ul>
          <li>With service providers who help us deliver our services</li>
          <li>If required by law or to protect our rights</li>
        </ul>
        We do not transfer or disclose your information to third parties for purposes other than the ones provided.
      </TermsText>

      <TextBrand>Data Security</TextBrand>

      <TermsText>
        We implement reasonable security measures to protect your information.
      </TermsText>

      <TextBrand>Your Rights</TextBrand>

      <TermsText>
        You can revoke our access to your Google account information at any time through
        your Google Account settings.
      </TermsText>

      <TextBrand>Changes to This Policy</TextBrand>

      <TermsText>
        We may update this policy periodically. We will notify you of any significant changes.
      </TermsText>

      <TextBrand>Contact Us</TextBrand>

      <TermsText>
        If you have questions about this policy, please contact us via the information below.
      </TermsText>

      <TextBrand>Google Ads</TextBrand>

      <TermsText>
        Google&lsquo;s advertising requirements can be summed up by Google&lsquo;s Advertising Principles. They are
        put in place to provide a positive experience for users.
        <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
          https://support.google.com/adwordspolicy/answer/1316548?hl=en
        </a>
      </TermsText>

      <TermsText>
        We have not enabled Google AdSense on our site but we may do so in the future.
      </TermsText>

      <TextBrand>California Online Privacy Protection Act</TextBrand>

      <TermsText>
        CalOPPA is the first state law in the nation to require commercial websites and online services to post a
        privacy policy. The law&lsquo;s reach stretches well beyond California to require any person or company in the
        United States (and conceivably the world) that operates websites collecting Personally Identifiable
        Information from California consumers to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals or companies with whom it is being shared. - See more at:
        <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
        </a>
      </TermsText>

      <TextBrand>According to CalOPPA, we agree to the following:</TextBrand>

      <TermsText>
        <ul>
          <li>Users can visit our site anonymously.</li>
          <li>
            Once this privacy policy is created, we will add a link to it on our home page or at a minimum, on the
            first significant page after entering our website.
          </li>
          <li>
            Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
          </li>
          <li>
            You will be notified of any Privacy Policy changes on our Privacy Policy Page.
          </li>
          <li>Can change your personal information by emailing us.</li>
        </ul>
      </TermsText>

      <TextBrand>How does our site handle Do Not Track signals?</TextBrand>

      <TermsText>
        We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT)
        browser mechanism is in place.
      </TermsText>

      <TextBrand>Does our site allow third-party behavioral tracking?</TextBrand>

      <TermsText>
        It&lsquo;s also important to note that we do not allow third-party behavioral tracking
      </TermsText>

      <TextBrand>COPPA (Children Online Privacy Protection Act)</TextBrand>

      <TermsText>
        When it comes to the collection of personal information from children under the age of 13
        years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control.
        The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA
        Rule, which spells out what operators of websites and online services must do to protect children’s
        privacy and safety online.
      </TermsText>

      <TextBrand>Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?</TextBrand>

      <TermsText>We do not specifically market to children under the age of 13 years old.</TermsText>

      <TextBrand>Fair Information Practices</TextBrand>

      <TermsText>
        The Fair Information Practices Principles form the backbone of privacy law in the United States,
        and the concepts they include have played a significant role in the development of data protection
        laws around the globe. Understanding the Fair Information Practice Principles and how they should
        be implemented is critical to comply with the various privacy laws that protect personal information.
      </TermsText>

      <TextBrand>
        In order to be in line with Fair Information Practices we will take the following responsive action,
        should a data breach occur:
      </TextBrand>

      <TermsText>
        <ul>
          <li> We will notify the users via in-site notification within 7 business days.</li>
        </ul>
        We also agree to the Individual Redress Principle, which requires that individuals have the right to legally
        pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle
        requires not only that individuals have enforceable rights against data users, but also that individuals have
        recourse to courts or government agencies to investigate and/ or prosecute non-compliance by data processors.
      </TermsText>

      <TextBrand>CAN SPAM Act</TextBrand>

      <TermsText>
        The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial
        messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough
        penalties for violations.
      </TermsText>

      <TextBrand>We collect your email address in order to:</TextBrand>

      <TermsText>
        <ul>
          <li>
            Market to our mailing list or continue to send emails to our clients after the original transaction has
            occurred.
          </li>
        </ul>

      </TermsText>

      <TextBrand>To be in accordance with CANSPAM, we agree that we will:</TextBrand>

      <TermsText>
        <ul>
          <li>Not use false or misleading subjects or email addresses.</li>
          <li>Identify the message as an advertisement in some reasonable way.</li>
          <li>Include the address of our business or site headquarters.</li>
          <li>Monitor third-party email marketing services for compliance, if one is used.</li>
          <li>Honor opt-out/unsubscribe requests quickly.</li>
          <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
        </ul>

        <b>
          If at any time you would like to unsubscribe from receiving future emails, you can email us at
          <a href="mailto://info@thefamilycore.com"> info@thefamilycore.com</a> and we will promptly remove you from
          ALL correspondence.
        </b>
      </TermsText>

      <TextBrand>Contacting Us</TextBrand>

      <TermsText>
        If there are any questions regarding this privacy policy, you may contact us using the information below.
        <br />
        The Family Core, LLC
        <br />
        PO Box 25236
        <br />
        Scottsdale, Az, 85255
        <br />
        <p><b>Email: </b><a href="mailto://info@thefamilycore.com">info@thefamilycore.com</a></p>
      </TermsText>
    </Content>
  </>
);
