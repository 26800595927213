import styled             from 'styled-components';
import { Button }         from '@material-ui/core';
import { useHistory }     from 'react-router-dom';
import { theme }          from '../../../styles/theme';
import ArrowBackIosIcon   from '@material-ui/icons/ArrowBackIos';
import { PrivacyContent } from '../../shared/components/PrivacyPolicy';

const Wrapper = styled.div`
  background: ${ theme.colors.white };
  position: relative;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 14px;
  left: 20px;
  cursor: pointer;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  font-family: 'Jost';
  font-weight: 500;
  color: #547cd3;

  :hover {
    background: none;
  }

  .MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
  }
`;

export const TermsAndPrivacyPage = () => {
  const history = useHistory();

  return(
    <Wrapper>
      <StyledButton
        startIcon={<ArrowBackIosIcon />}
        onClick={() => history.goBack()}
      >
        Back
      </StyledButton>

      <PrivacyContent />
    </Wrapper>
  );
};
