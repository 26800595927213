import { Box, Checkbox, FormControlLabel, Grid }                   from '@material-ui/core';
import {
  FastField,
  Field,
  FormikProps,
  useFormikContext,
}                                      from 'formik';
import {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useState,
}                                      from 'react';
import styled                          from 'styled-components';
import { ReactComponent as IconEvent } from '../../../../../images/icons/icon-landscape.svg';
import { documentAccessLevels }        from '../../../constants/constants';
import { CheckboxField }               from '../../../formFields/CheckboxField';
import { DateRangeField }              from '../../../formFields/DateRangeField';
import { FamilyMembersSelector }       from '../../../formFields/FamilyMembersSelector';
import { SelectField }                 from '../../../formFields/SelectField';
import { SelectTimeField }             from '../../../formFields/SelectTimeField';
import { TextInputField }              from '../../../formFields/TextInputField';
import { WithCategorySelection }       from '../../../hocs/withCategorySelection';
import { useFetchCategories }          from '../../../queries/categories';
import { StyledDivider }               from '../../../styledComponents';
import { ColorPickerDropdown }         from '../../ColorPicker/ColorPickerDropdown';
import { DocumentPicker }              from '../../documents/DocumentPicker';
import {
  UploadEntityFormDropdownField,
  UploadEntityFormLabel,
  UploadEntityFormRow,
  UploadEntityFormSpacing,
}                                      from '../../documents/styles';
import { GridDivider }                 from '../../layout/GridDivider';
import { RepeatOptions }               from '../../RepeatOptions/RepeatOptions';
import {
  ERecurrence,
  IRepeatOptions,
}                                      from '../shared';
import { ModalFooterActions }          from '../shared/ModalFooterActions';
import { IUploadEvent }                from './queries';
import { IEvent }                      from '../../../../modules/Schedule/queries';
import { LabelText }                   from '../../../../Auth/shared/styles';

interface IAddEventModalFormProps {
  familyId?: string;
  schedule?: Array<IEvent>;
  loading  : boolean;
  files    : File[];
  event    : any;
  formikRef: RefObject<FormikProps<IUploadEvent>>;
  disableRecursField?: boolean;
  onFileAdd(files: File[]): void;
}

const GridTimeWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

const TimeBlock = styled(Grid)`
  position: relative;
`;

const ErrorTextBlock = styled('p')`
  position: absolute;
  color: #f44336;
  font-size: 0.7rem;
`;

export const UploadEventModalForm: FC<IAddEventModalFormProps> = ({
  familyId,
  schedule,
  event,
  loading,
  files,
  onFileAdd,
  formikRef,
  disableRecursField,
}) => {
  const { values, setFieldValue, handleBlur, handleChange, errors } = useFormikContext<IUploadEvent>();
  const { data: categories, isLoading: loadingCategories } = useFetchCategories(familyId);
  const [timePickerShow, setTimePieckerShown] = useState(!values.allDay);
  const [endTimePickerShow, setEndTimePieckerShown] = useState(Boolean(values.endDate));
  const [ErrorText, setErrorText] = useState<null | string>(null);

  useEffect(() => {
    if(!endTimePickerShow) {
      setFieldValue('endDate', '');
      setErrorText(null);
    }
  }, [endTimePickerShow]);

  useEffect(() => {
    if (values?.recursStartTime && values?.endDate) {

      const startDate = new Date(values.recursStartTime);
      const endDate = new Date(values.endDate);

      if ((endDate > startDate) || (endDate == startDate) && !values?.recursEndDate) {
        setErrorText(null);
      } else if ((endDate > startDate) || (endDate < startDate) && values?.recursEndDate) {
        setErrorText(null);
      } else {
        setErrorText('End date must be later than start.');
      }
    }
  }, [values?.recursStartTime, values?.endDate, values?.recursEndDate, errors]);

  useEffect(
    () => {
      !!values.allDay ? setTimePieckerShown(false) : setTimePieckerShown(true);
    },
    [values.allDay]
  );

  const updateRepeatOptions = (options: IRepeatOptions) => {
    setFieldValue('recurs', options?.recurs);
    setFieldValue('recursInterval', options?.recursInterval);
    setFieldValue('recursDay', options?.recursDay);
    setFieldValue('recursOrdinal', options?.recursOrdinal);
    setFieldValue('recursWeekDays', options?.recursWeekDays);
    setFieldValue('recursCount', options?.recursCount);

    if (values?.recursEndDate !== options?.recursEndDate) {
      setFieldValue('recursEndDate', options?.recursEndDate);
    }
  };

  const repeatOptions = {
    recurs          : values.recurs || ERecurrence.NoRecurrence,
    recursInterval  : values.recursInterval,
    recursDay       : values.recursDay,
    recursOrdinal   : values.recursOrdinal,
    recursWeekDays  : values.recursWeekDays,
    recursCount     : values.recursCount,
    recursStartDate : values.recursStartDate,
    recursEndDate   : values.recursEndDate,
  };

  useEffect(() => {
    if (values.allDay) {
      setFieldValue('recursStartTime', '');
      setFieldValue('endDate', '');
      setTimePieckerShown(false);
      setEndTimePieckerShown(false);
    } else {
      setTimePieckerShown(true);
    }
  }, [values.allDay]);

  useEffect(() => {
    if(event?.endDate) {
      setFieldValue('endDate', event.endDate);
      setEndTimePieckerShown(true);
    } else {
      setFieldValue('endDate', '');
    }
  }, [event]);

  const Form = WithCategorySelection(({
    categoriesDropdown,
  }) => (
    <UploadEntityFormSpacing>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <FastField
            label="Event Title"
            name={'title'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />

          <GridDivider margin="20px 0 0" />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Category</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              { categoriesDropdown }
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Assign</UploadEntityFormLabel>

            <FamilyMembersSelector
              multiple
              hideAllSelection
              name="assigned"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel width="135px">Assignee Permissions</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              <FastField
                name="assignedPermission"
                options={documentAccessLevels}
                component={SelectField}
              />
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Tag</UploadEntityFormLabel>

            <FamilyMembersSelector
              multiple
              hideAllSelection
              name="tagged"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Due Date</UploadEntityFormLabel>

            <GridTimeWrapper container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FastField
                  startName="recursStartDate"
                  endName="recursEndDate"
                  component={DateRangeField}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Field
                  readOnly
                  label="All Day"
                  name="allDay"
                  component={CheckboxField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {timePickerShow && (
                  <FormControlLabel
                    label={<LabelText>End Time</LabelText>}
                    control={
                      <Checkbox
                        checked={endTimePickerShow || !!values?.endDate}
                        onChange={() => setEndTimePieckerShown(!endTimePickerShow)}
                      />
                    }
                  />
                )}
              </Grid>

              {timePickerShow && (
                <TimeBlock item xs={9} sm={4}>
                  <Field
                    name="recursStartTime"
                    component={SelectTimeField}
                    schedule={schedule}
                  />

                  {(endTimePickerShow || (values?.endDate && endTimePickerShow)) && (
                    <>
                      <Field
                        name="endDate"
                        component={SelectTimeField}
                        schedule={schedule}
                      />
                      <ErrorTextBlock>{errors.recursStartTime ? errors.recursStartTime : ErrorText}</ErrorTextBlock>
                    </>
                  )}
                </TimeBlock>
              )}

              <Grid item xs={2} sm={1}>
                <RepeatOptions
                  values={repeatOptions}
                  disabled={disableRecursField}
                  onChange={updateRepeatOptions}
                />
              </Grid>

            </GridTimeWrapper>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FastField
            fullWidth
            label="Add Location"
            name="location"
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Set Color</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              <ColorPickerDropdown name="color" />
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FastField
            multiline
            fullWidth
            rows={4}
            label="Description"
            name={'description'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />
        </Grid>

        <GridDivider margin="-5px 0" />

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormLabel>Attach File</UploadEntityFormLabel>

          <Box height={10} />

          <DocumentPicker
            multiple
            icon={<IconEvent />}
            files={files}
            onChange={onFileAdd}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ModalFooterActions
            formikRef={formikRef}
            disabled={loading || !files}
          >
            { loading ? 'Uploading' : values?.id ? 'Edit' : 'Add' } Event
          </ModalFooterActions>
        </Grid>
      </Grid>
    </UploadEntityFormSpacing>
  ));

  return useMemo(() => (
    <Form
      values={values}
      familyId={familyId}
      categories={categories}
      loading={loadingCategories}
      setFieldValue={setFieldValue}
    />
  ), [
    files,
    loading,
    categories,
    event,
    values?.recursStartDate,
    values?.recursEndDate,
    values?.alertInterval,
    values?.categoryId,
    values?.recursOrdinal,
    values?.recursDay,
    values?.allDay,
    values?.endDate,
    timePickerShow,
    endTimePickerShow,
    values?.recurs,
    ErrorText,
    errors,
  ]);
};
