import { addSpaceBeforeCapitals } from '../utils/commonFunctions';

export enum EAuthRoutes {
  Auth                    = '/auth',
  SignIn                  = '/auth/sign-in',
  SignUp                  = '/auth/sign-up',
  ForgotPassword          = '/auth/forgot-password',
  ResetPassword           = '/auth/reset-password',
  AcceptInvitation        = '/auth/accept-invitation',
  CodeConfirmation        = '/auth/confirmation',
  EmailConfirmation       = '/auth/confirm-email',
  EmailChangeConfirmation = '/auth/confirm-change-email',
  CreateFamily            = '/auth/create-family',
  Checkout                = '/auth/checkout',
  CustomizeProfile        = '/auth/customize-profile',
  CreatePin               = '/auth/create-pin',
  SelectFamilyComponent   = '/auth/select-family',
  ChangeSubscription      = '/auth/change-subscription',
}

export enum EAppRoutes {
  Dashboard                = '/dashboard',
  Activity                 = '/activity',
  Schedule                 = '/schedule',
  Locate                   = '/locate',
  MessageCenter            = '/message-center',
  ActivityAll              = '/activity/all',
  Settings                 = '/settings',
  MyProfile                = '/settings/my-profile',
  ManageUsers              = '/settings/manage-family',
  ManageUsersAccount       = '/settings/manage-family/account',
  ManageUsersAbout         = '/settings/manage-family/about',
  ManageUsersPermissions   = '/settings/manage-family/permissions',
  ManageUsersTaggedItems   = '/settings/manage-family/tagged-items',
  ManageUsersAssignedItems = '/settings/manage-family/assigned-items',
  AddMembers               = '/add-members',
  MyFamilyCore             = '/my-family-core',
  AboutSummary             = '/my-family-core/about',
  DocumentsSummary         = '/my-family-core/documents',
  TasksSummary             = '/my-family-core/tasks',
  ContactsSummary          = '/my-family-core/contacts',
  NotificationPreferences  = '/notifications-and-preferences',
  Terms                    = '/terms-privacy',
  // Terms                    = '/terms-privacy',
  AdminStatistics          = '/admin-statistics',
}

export enum EAccountStatus {
  Accepted = 'Accepted',
  Pending  = 'Pending'
}

export enum ETaskStatus {
  Open     = 'Open',
  Added    = 'Added',
  Changed  = 'Changed',
  Complete = 'Complete',
  Deleted  = 'Deleted',
  Overdue  = 'Overdue'
}

export enum PlatformNotificationType {
  None,
  ConfirmEmail,
  ResetPassword,
  EmailCode,
  ConfirmEmailChange,
  InviteEmail,
  ResetSecurityCode,
  InviteReminder,
  InformFamilyAccountDeleteEmail,
  InformMemberAccountDeleteEmail,
  BirthdayReminder,
  TaskUpdated,
  TaskDeleted,
  EventUpdated,
  EventDeleted,
  ContactUpdated,
  ContactDeleted,
  NewMember,
  NewMessage,
  DailySummary,
  CsvMarketing,
  DocumentViewed = "DocumentViewed",
  AboutMeUpdated = "AboutMeUpdated",
  GeolocationDisabled = "GeolocationDisabled",
  EventReminder = "EventReminder",
  TaskReminder = "TaskReminder",
  Checkin = "Checkin",
  DocumentAdded = "DocumentAdded",
  DocumentUpdated = "DocumentUpdated",
  DocumentDeleted = "DocumentDeleted",
  ContactAdded = "ContactAdded",
  EventAdded = "EventAdded",
  TaskAdded = "TaskAdded"
}

export enum PLATFORM_TAG {
  'desktop' = 'Desktop',
  'android' = 'Android',
  'ios'     = 'IOS',
}

export enum SUBSCRIPTION_TAG {
  'null'   = 'Free',
  'Stripe' = 'Premium - Stripe',
  'Apple'  = 'Premium - Apple',
  'Google' = 'Premium - Google',
}

type TEventType = {
  [key: string]: string;
};

export const EVENT_TYPE: TEventType = {
  AboutMeUpdated      : "About Me Info Updated",
  EventReminder       : "Event Reminder",
  GeolocationDisabled : "Geolocation Disabled",
  TaskReminder        : "Task Reminder",
  EventAdded          : "Event Added",
  DocumentAdded       : "Document Added",
  DocumentUpdated     : "Document Updated",
  DocumentDeleted     : "Document Deleted",
  TaskAdded           : "Task Added",
  ContactAdded        : "Contact Added",
};

export const taskStatusesList = [ {
  id    : '',
  value : 'Status - All',
}, {
  id    : ETaskStatus.Open,
  value : ETaskStatus.Open,
}, {
  id    : ETaskStatus.Complete,
  value : ETaskStatus.Complete,
}, {
  id    : ETaskStatus.Deleted,
  value : ETaskStatus.Deleted,
} ];

export enum EGender {
  Male   = 'Male',
  Female = 'Female',
}

export enum EDocumentAccess {
  ViewOnly    = 'ViewOnly',
  ViewAndEdit = 'ViewAndEdit',
}

export const documentAccessLevels = [ {
  id    : EDocumentAccess.ViewOnly,
  label : 'View Only',
}, {
  id    : EDocumentAccess.ViewAndEdit,
  label : 'Members Can Edit',
} ];

export enum EDateFilter {
  Today     = 'Today',
  ThisWeek  = 'ThisWeek',
  ThisMonth = 'ThisMonth'
}

export const dateFilterOptions = [ {
  id    : '',
  value : 'Date - All',
}, {
  id    : EDateFilter.Today,
  value : addSpaceBeforeCapitals(EDateFilter.Today),
}, {
  id    : EDateFilter.ThisWeek,
  value : addSpaceBeforeCapitals(EDateFilter.ThisWeek),
}, {
  id    : EDateFilter.ThisMonth,
  value : addSpaceBeforeCapitals(EDateFilter.ThisMonth),
} ];

export const fileTypesString = 'JPEG, PNG, or GIF';
export const fileTypeMessage = `Must be ${ fileTypesString } and cannot exceed 10MB.`;

export const colors = [
  '#547cd3', '#fa5ec6', '#0dc695',
  '#fac05e', '#fa5ec6', '#631a86',
  '#fe938c', '#ffe66d', '#abdafc',
  '#551b14', '#00f0b5', '#0075c4',
  '#1b998b', '#00cecb', '#f374ae',
  '#248232', '#fd5b78', '#bfafb2',
  '#c95a49', '#353839', '#dde26a',
  '#a0e6ff', '#391285', '#8c90c8',
  '#4a646c',
];

export const bloodTypes = [
  'A RhD positive (A+)',
  'A RhD negative (A-)',
  'B RhD positive (B+)',
  'B RhD negative (B-)',
  'O RhD positive (O+)',
  'O RhD negative (O-)',
  'AB RhD positive (AB+)',
  'AB RhD negative (AB-)',
];

export const imageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
];

export const documentsMimeTypes = [
  '.dot',
  '.docx',
  '.dotx',
  '.docm',
  '.dotm',
  '.xls',
  '.xlt',
  '.xla',
  '.xlsx',
  '.xltx',
  '.xlsm',
  '.xltm',
  '.xlam',
  '.xlsb',
  '.ppt',
  '.pot',
  '.pps',
  '.ppa',
  '.pptx',
  '.potx',
  '.ppsx',
  '.ppam',
  '.pptm',
  '.potm',
  '.ppsm',
  'application/pdf',
  'application/msword',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
];

export const familyCoreConfig = {
  isChatEnabled        : true,
  isGeolocationEnabled : true,
};

export const userPlatform = 'user-platform';

